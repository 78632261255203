import React, { useContext } from "react";
import Header, { HeaderLeft, HeaderRight } from "../../../layout/Header/Header";
import Button, { IButtonProps } from "../../../components/bootstrap/Button";
import ThemeContext from "../../../contexts/themeContext";

import useDarkMode from "../../../hooks/useDarkMode";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "react-use";
// import Modal, {
//   ModalBody,
//   ModalHeader,
//   ModalTitle,
// } from "../../../components/bootstrap/Modal";
// import Icon from "../../../components/icon/Icon";

const BombmyHeader = () => {
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();
  // const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const styledBtn: IButtonProps = {
    color: "brand",
    hoverShadow: "default",
    isLight: !darkModeStatus,
    size: "lg",
  };
  const windowsWidth = useWindowSize().width;

  const {
    asideStatus,
    setAsideStatus,
    setLeftMenuStatus,
    rightMenuStatus,
    setRightMenuStatus,
  } = useContext(ThemeContext);

  return (
    <Header type="bombmy">
      <HeaderLeft>
        {windowsWidth >= 760 ? (
          <Button
            to="/jofans"
            onClick={() => navigate("/bombmy")}
            hoverShadow="none"
            isLink
          >
            <img
              height={"48px"}
              width={"100%"}
              src="/resources/images/logo/logo_banner.webp"
              alt="logo"
              // onClick={() => navigate("/bombmy")}
              style={{ cursor: "pointer" }}
            />
          </Button>
        ) : null}
      </HeaderLeft>

      <HeaderRight>
        <div className="row g-4">
          <div className="col">
            <Button
              {...styledBtn}
              // to={'/index'}
              className="w-100"
              onClick={() => {
                if (rightMenuStatus) {
                  setRightMenuStatus(false);
                }

                window.location.href="https://index.bombmy.live/teaching";
              }}
              data-tour="dark-mode"
            >
              新手教學
            </Button>
          </div>

          <div className="col">
            <Button
              {...styledBtn}
              // to={'/index'}
              className="w-100"
              data-tour="dark-mode"
              onClick={() => (window.location.href = "https://www.bombmy.live")}
            >
              立即開始
            </Button>
          </div>
          {/* <div className="col">
            <Button
              {...styledBtn}
              to={"/index"}
              className="w-100"
              data-tour="dark-mode"
              onClick={() => setIsOpenModal(true)}
            >
              購買須知
            </Button>
          </div> */}
          {/* <div className="col-auto">
            <Button
              {...styledBtn}
              to={'/index'}
              data-tour="dark-mode"
              onClick={() => navigate('/index')}
            >
              首頁
            </Button>
          </div>
          <div className="col-auto">
            <Button
              {...styledBtn}
              to={'/pricing'}
              data-tour="dark-mode"
              onClick={() => navigate('/pricing')}
            >
              收費方式
            </Button>
          </div>
          <div className="col-auto">
            <Button {...styledBtn} data-tour="dark-mode">
              相關文章
            </Button>
          </div> */}

          {/* <Modal
            isOpen={isOpenModal}
            setIsOpen={setIsOpenModal}
            titleId="tour-title"
          >
            <ModalHeader setIsOpen={setIsOpenModal}>
              <ModalTitle id="tour-title" className="d-flex align-items-end">
                <span className="ps-2">
                  <Icon icon="AddAlert" color="brand" />
                </span>
                <span className="ps-2">購物須知及相關問題</span>
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <div className="row d-flex justify-content-center">
                <div className="col-md-10">
                  <div>
                    <h4 className="text-center font-weight-bold"> 購物須知</h4>
                    <br />
                    <h5 style={{ fontWeight: "bold" }}>1.購物流程：</h5>
                    <br />
                    <h5>
                      選擇方案 -&gt; 填寫資料及付款方式和發票資料 -&gt; 付款
                      -&gt; 完成 -&gt; 開通帳號 -&gt; 開始使用幫賣！
                    </h5>
                    <br />
                    <h5 style={{ fontWeight: "bold" }}>2.發票開立：</h5>
                    <br />
                    <h5>付款完成後，將於7個工作天內寄送紙本發票至聯絡地址。</h5>
                    <br />
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal> */}
        </div>
      </HeaderRight>
    </Header>
  );
};

export default BombmyHeader;
