import React, { FC, useCallback, useState, ReactNode,useEffect } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
// import Select from '../../../components/bootstrap/forms/Select';
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Badge from "../../../components/bootstrap/Badge";
import data, { CATEGORIES, TTags } from "./helper/dummyKnowledgeData";
// import { demoPages } from '../../../menu';
import { useWindowSize } from "react-use";
import useDarkMode from "../../../hooks/useDarkMode";
import useTourStep from "../../../hooks/useTourStep";
import { TColor } from "../../../type/color-type";

interface IItemProps {
  id: string | number;
  image: string;
  title: string;
  description: ReactNode;
  tags: TTags[];
  color: TColor;
}
const Item: FC<IItemProps> = ({
  id,
  image,
  title,
  description,
  tags,
  color,
}) => {
  useTourStep(15);
  const { darkModeStatus } = useDarkMode();

  const windowsWidth = useWindowSize().width;
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate(`${id}`), [navigate, id]);
  return (
    <Card
      className="cursor-pointer shadow-3d-brand shadow-3d-hover"
      onClick={handleOnClick}
      data-tour={title}
    >
      <CardBody>
        <div
          className={classNames(
            "ratio ratio-1x1",
            "rounded-2",
            `bg-l${darkModeStatus ? "o25" : "10"}-${color}`,
            "mb-3"
          )}
        >
          <div className="ratio ratio-1x1 me-3">
            <div
              className={classNames(
                `text-${color}`,
                `
							display-4 rounded-2 d-flex align-items-center justify-content-center`
              )}
            >
              <span
                className="fw-bold"
                style={{ fontSize: windowsWidth < 600 ? 24 : 40 }}
              >
                {image}
              </span>
            </div>
          </div>

          {/* <img
						src={image}
						alt=""
						width="100%"
						height="auto"
						className="object-fit-contain p-3"
					/> */}
        </div>
        <CardTitle>{title}</CardTitle>
        <div style={{ height: 40, overflow: "hide" }}>
          <p className="text-muted truncate-line-2">{description}</p>
        </div>

        <div className="row g-2">
          {!!tags &&
            // eslint-disable-next-line react/prop-types
            tags.map((tag) => (
              <div
                key={tag.text}
                className="col-auto"
                style={{ fontSize: "24px" }}
              >
                <Badge isLight color={tag.color} className="px-3 py-2">
                  {tag.text}
                </Badge>
              </div>
            ))}
        </div>
      </CardBody>
    </Card>
  );
};

const Instructions = () => {
  const { darkModeStatus } = useDarkMode();
  const [filterableData, setFilterableData] = useState(data);
  const [teachSearch, setTeachSearch] = useState<any>();
  // 新手教學的文章框架
  useEffect(() => {
    window.location.href = "https://index.bombmy.live/teaching";
  }, []);
  const debounce = (func: any, wait: number | undefined) => {
    let timeout: string | number | NodeJS.Timeout | undefined;

    return function executedFunction(...args: any[]) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  const searchAndFilterData = (searchValue: string, category: string) => {
    let tempData = data;

    // if (category)
    // 	tempData = data.filter((item) =>
    // 		item.categories.find((categ) => categ.value === category)
    // 	);

    return tempData.filter((item) => {
      return (
        item.title.toLowerCase().includes(searchValue) ||
        item.description.toLowerCase().includes(searchValue) ||
        //item.content.toLowerCase().includes(searchValue) ||
        // item.categories.find((categ) =>
        // 	categ.text.toLowerCase().includes(searchValue)
        // ) ||
        item.tags.find((tag) => tag.text.toLowerCase().includes(searchValue))
      );
    });
  };
  const onFormSubmit = (values: { category: any; search: any }) => {
    const searchValue = values.search.toString().toLowerCase();
    const newData = searchAndFilterData(searchValue, values.category);

    if (!values.search && !values.category) {
      setFilterableData(data);
    } else {
      setFilterableData(newData);
    }
  };
  const formik = useFormik({
    initialValues: {
      search: "",
      category: "",
    },
    onSubmit: onFormSubmit,
    onReset: () => setFilterableData(data),
  });

  return (
    <PageWrapper title="新手教學">
      <Page container="sm">
        <div className="row">
          <div className="col-12 text-center my-5">
            <span className="display-5 fw-bold">
              BombMy幫賣，廠商設定步驟說明
            </span>
          </div>

          <div
            className="col-xxl-6 mx-auto text-center my-5"
            data-tour="knowledge-filter"
          >
            <form
              className={classNames("row", "pb-4 px-3 mx-0 g-4", "rounded-3", [
                `bg-l${darkModeStatus ? "o25" : "10"}-brand`,
              ])}
              onSubmit={formik.handleSubmit}
            >
              <div className="col-md-10">
                {/* 搜尋關鍵字 */}
                <Input
                  id="search"
                  size="lg"
                  placeholder="請輸入關鍵字..."
                  className={classNames("rounded-1", {
                    "bg-white": !darkModeStatus,
                  })}
                  onChange={(e: { target: { value: string | any[] } }) => {
                    formik.handleChange(e);
                    setTeachSearch(e.target.value);
                    if (e.target.value.length > 1)
                      debounce(
                        () =>
                          onFormSubmit({
                            ...formik.values,
                            search: e.target.value,
                          }),
                        1000
                      )();

                    if (e.target.value.length === 0) formik.resetForm();
                  }}
                  value={teachSearch}
                />
              </div>
              <div className="col-md-2">
                <Button
                  size="lg"
                  icon="Close"
                  color="brand"
                  className="w-100"
                  rounded={1}
                  onClick={formik.resetForm}
                  type="reset"
                  isDisable={!(formik.values.search || formik.values.category)}
                />
              </div>
            </form>
          </div>
          <div className="col-xxl-12 mx-auto text-center ">
            <Card shadow={"none"}>
              <CardBody>
                <h2 className="text-danger">
                  提醒您依序完成步驟一至九，才能建立一場完整的直播喔！
                </h2>
                <br />
                本系統為根垣科技有限公司製作 ，
                以下是操作的流程，首先是建立帳號、上傳影片、製作報名頁等......讓我們開始一起建立直播吧！
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="row mb-5">
          {filterableData.map((item) => (
            <>
              <div key={item.id} className="col-xl-3 col-lg-4 col-md-6 col-6">
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Item {...item} />
              </div>
            </>
          ))}
          {filterableData.length ? null : (
            <h4 className="font-size-bold">查無此資料....</h4>
          )}
        </div>
      </Page>
    </PageWrapper>
  );
};

export default Instructions;
